import React from "react";
import images from "../../constants/images";

import "./AboutUs.css";

const AboutUs = () => (
  <div
    className="app__aboutus app__bg flex__center section__padding"
    id="about"
  >

  {/*
    <div className="app__aboutus-overlay flex__center">
      <img src={images.logo} alt="" />
    </div>
  */}

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">About us</h1>
        <img src={images.spoon} alt="" className="spoon__img" />
        <p className="p__opensans">
          Zagaleta offers a unique fusion of luxury and simplicity in Abuja, delivering an unforgettable dining experience with each meticulously crafted dish in an elegantly inviting ambiance.
        </p>
        {/*
        <button type="button" className="custom__button">
          Know more
        </button>*/}
      </div>
    

      <div className="app__aboutus-content_utensil flex__center">
        <img src={images.utensil} alt="" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Our History</h1>
        <img src={images.spoon} alt="" className="spoon__img" />
        <p className="p__opensans">
        Born from a vision to transform Abuja's culinary scene, Zagaleta has been synonymous with sophisticated dining. Our journey reflects a commitment to exceptional flavors and memorable experiences.
        </p>
        {/*
        <button type="button" className="custom__button">
          Know more
        </button>
      */}
      </div>
    </div>
  </div>
);

export default AboutUs;
