import React from "react";

import { SubHeading, MenuItem } from "../../components";
import { images, data } from "../../constants";

import "./SpecialMenu.css";

const SpecialMenu = () => (
  <div className="app__specialMenu flex__center section__padding " id="menu">
    <div className="app__specialMenu-title">
      <SubHeading title="Menu wey dey for everyone" />
      <h1 className="headtext__cormorant">Our Special</h1>
    </div>

    <div className="app__specialMenu-menu">
      <div className="app__specialMenu-menu_wine flex__center">
        <p className="app__specialMenu-menu_heading">Starters</p>
        <div className="app__specialMenu-menu_items">
        {data.wines.map((wine, index) => (
          <MenuItem key={wine.title + index} title={wine.title} price={wine.price}/>
        ))}
        </div>
      </div>

      <div className="app__specialMenu-menu_cocktail flex__center">
        <p className="app__specialMenu-menu_heading">Starters</p>
        <div className="app__specialMenu-menu_items">
        {data.cocktails.map((cocktail, index) => (
          <MenuItem key={cocktail.title + index} title={cocktail.title} price={cocktail.price} />
        ))}
        </div>
      </div>
    </div>

    <div style={{marginTop: '15px'}}>
          <button type="button" className="custom__button">View more</button>
    </div>

  </div>
);

export default SpecialMenu;
