import React from "react";

import { SubHeading } from '../../components'
import images from "../../constants/images";

import "./Header.css";

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
        <SubHeading  title='Food | Luxury spot | Exclusive Lounge'/>

        <h1 className="app__header-h1">Luxury in Every Bite</h1>
    <p className="p__opensans" style={{margin: '2rem 0'}}>Indulge in simple, luxurious dining with every bite, in the heart of Abuja's vibrant culinary scene</p>
    <a href="#menu"><button type="button" className="custom__button" >Explore Menu</button>  </a>  
    </div>

    <div className="app__header_img">
    <img src={images.welcome} alt="" />
    </div>
  </div>
);

export default Header;
