import React from "react";

import SubHeading from "../SubHeading/SubHeading";
import "./Newsletter.css";

const Newsletter = () => (
  <div className="app__newsletter">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3939.871636728634!2d7.472872074859248!3d9.075456990987728!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0b5273e9d2d3%3A0xf54230a4af08c47e!2sZagaleta%20Restaurant%20%26%20Lounge!5e0!3m2!1sen!2sng!4v1704659268062!5m2!1sen!2sng"
      width="1000"
      height="500"
      style={{ border: "" }}
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>

    {/*
    <div className="app__newsletter-heading">
      <SubHeading title="Newsletter" />
      <h1 className="headtext__cormorant">Subscribr to our Newsalerreyer</h1>
      <p className="p__opensans">to never miss any update</p>
    </div>
    <div className="app__newsletter-input flex__center">
      <input type="email" placeholder="Type Your Email Address" />
      <button className="custom__button">Subscribe</button>
    </div>
    */}
  </div>
);

export default Newsletter;
