import React from "react";

import { FooterOverlay, Newsletter } from "../../components";
import { images } from "../../constants";
import { FiFacebook, FiX, FiInstagram,} from "react-icons/fi";

import "./Footer.css";

const Footer = () => (
  <div className="app__footer section__padding" style={{ color: "white" }}>
    <FooterOverlay />
    <Newsletter />

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">Zagaleta Restaurant & Lounge Ahmadu Bello Way, Wuse 2, Abuja</p>
        <p className="p__opensans">133232399</p>
        <p className="p__opensans">+88 32389083092</p>
      </div>
      <div className="app__footer-links_logo">
        <img src={images.logo} alt="logo" />
        <p className="p__opensans">We know what you want</p>
        <img
          src={images.spoon}
          alt="spoon"
          className="spoon__img"
          style={{ marginTop: 15 }}
        />
        <div className="app__footer-links_icons">
          <FiFacebook />
          <FiX />
          <FiInstagram />
        </div>
      </div>
      <div className="app__footer-links_work">
      <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans">Mon- Fri</p>
        <p className="p__opensans">9am - 9pm</p>
        <p className="p__opensans">Sat- Sun</p>
        <p className="p__opensans">10am - 8pm</p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">2023 Amala.All Rights reserved</p>
      <p className="p__opensans">Designed by umar</p>
    </div>
  </div>
);

export default Footer;
