import React from "react";

import { SubHeading } from "../../components";
import { images } from "../../constants";

const FindUs = () => (
  <div className="app__bg app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
      <SubHeading title="contact" />
      <h1
        className="headtext__cormorant"
        style={{ color: "yellow", marginBottom: "3rem" }}
      >
        Find Us
      </h1>
      <div className="app__wrapper-content">
        <p className="p__opensans">
        Zagaleta Restaurant & Lounge Ahmadu Bello Way, Wuse 2, Abuja
        </p>
        <p
          className="p__cormorant"
          style={{ color: "yellow", margin: "2rem 0" }}
        >
          Opening Hours
        </p>
        <p className="p__opensans">Mon - Fri: 9am - 9pm</p>
        <p className="p__opensans">Sat - Sun: 10am - 8pm</p>
      </div>
      <a href="https://maps.app.goo.gl/2qLYPwbatnyUopJA7" target="_blank">
        <button className="custom__button" style={{ marginTop: "2rem" }}>
          Visit Us
        </button>
      </a>
    </div>
    <div className="app__wrapper_img">
      <img src={images.findus} alt="pic" style={{border: "5px solid #d6eb1b"}} />
    </div>
  </div>
);

export default FindUs;
