import images from './images';

const wines = [
  {
    title: 'Village Rice',
    price: '3100',
  },
  {
    title: 'Jollof Rice',
    price: '1800',
  },
  {
    title: 'Coconut Rice',
    price: '2100',
  },
  {
    title: 'Fried Rice',
    price: '2000',
  },
  {
    title: 'White Rice',
    price: '1500',
  },
];

const cocktails = [
  {
    title: 'Assorted Pepper Soup',
    price: '4200',
  },
  {
    title: "Beef Pepper Soup",
    price: '4000',
  },
  {
    title: 'Chicken Pepper Soup',
    price: '4500',
  },
  {
    title: 'Cowleg Pepper Soup',
    price: '3500',
  },
  {
    title: 'Goat Meat Pepper Soup',
    price: '4800',
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Bib Gourmond',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award01,
    title: 'Rising Star',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
];

export default { wines, cocktails, awards };
